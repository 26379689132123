import React from "react"
import styled from "styled-components"

import { headlineFour, colors } from "../../Utilities"

const ButtonStyled = styled.a`
  ${headlineFour};

  &:hover {
    color: ${colors.colorPrimary};
  }
`

const AnchorOneText = ({ url, children }) => {
  return (
    <>
      <ButtonStyled href={url}>{children}</ButtonStyled>
    </>
  )
}

export default AnchorOneText
