const theme = {
  colorPrim: "#176CB5",
  colorSec: "#9ECC48",
  colorTert: "#038A96",
  colorAcc: "#B5DEDF",
  colorShad: "#F4F4F4",
  white: "#fff",
  grey: "#65646A",
  black: "#000",
  blackLight: "#505b61",
  strongred: "#f00",
  maxWidth: "1000px",
  baseLineHeight: "1.5",
  bpTablet: "768px",
  bpDesksm: "1025px",
  bpDeskmd: "1200px",
  bpDesklg: "1600px",
  bpMax: "1900px",
  fontPrim: "Open Sans",
  fontSec: "Montserrat",
  fontAwesome: "FontAwesome",
}

export default theme
