import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

import { buttonOne, buttonOneAlt } from "../../Utilities"

const ButtonStyled = styled(Link)`
  ${buttonOne};
  ${props => props.color === "alt" && buttonOneAlt}
`

const LinkOne = ({ color, children, url }) => {
  return (
    <>
      <ButtonStyled to={url} color={color}>
        {children}
      </ButtonStyled>
    </>
  )
}

LinkOne.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.node.isRequired,
}

export default LinkOne
