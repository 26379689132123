import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { buttonOne, buttonOneAlt } from "../../Utilities"

const ButtonStyled = styled.a`
  ${buttonOne};
  ${props => props.color === "alt" && buttonOneAlt}
`

const AnchorOne = ({ color, children, url, target }) => {
  const meta = target ? `_blank` : ""
  const rel = target ? `noopener noreferrer` : ""
  return (
    <>
      <ButtonStyled target={meta} href={url} rel={rel} color={color}>
        {children}
      </ButtonStyled>
    </>
  )
}

AnchorOne.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.node.isRequired,
}

export default AnchorOne
