import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import styled from "styled-components"

const LogoStyled = styled.div`
  width: 100%;
  max-width: 50rem;
  padding: 1rem;

  img {
    width: 100%;
  }
`

const Logo = ({ fluid, alt }) => {
  return (
    <LogoStyled>
      <Img fluid={fluid} alt={alt} />
    </LogoStyled>
  )
}

Logo.propTypes = {
  alt: PropTypes.node.isRequired,
  fluid: PropTypes.any.isRequired,
}

export default Logo
