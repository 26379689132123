export const colorPrimary = "#176CB5"
export const colorSecondary = "#9ECC48"
export const colorTertiary = "#038A96"
export const colorAccent = "#B5DEDF"
export const colorShad = "#F4F4F4"
export const veryLightBlue = "#e2edf6"
export const iceBlue = "#e5f3f4"
export const grey = "#65646A"
export const white = "#fff"
export const black = "#000"
export const blackLight = "#505b61"
export const strongRed = "#f00"

export default {
  colorPrimary,
  colorSecondary,
  colorTertiary,
  colorAccent,
  colorShad,
  veryLightBlue,
  iceBlue,
  grey,
  white,
  black,
  blackLight,
  strongRed,
}
