import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"

import Logo from "./Logo"
import WrapperDiv from "../../StyledElements/WrapperDiv"
import LinkOne from "../../StyledElements/LinkOne"
import AnchorOneText from "../../StyledElements/AnchorOneText"
import AnchorOne from "../../StyledElements/AnchorOne"
import { buttonOne } from "../../../Utilities"

const getData = graphql`
  query headerOptions {
    wordpressAcfOptions {
      options {
        swb_theme_main_logo {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`

const ScrollButtonOne = styled.button`
  ${buttonOne};
`

const HeaderStyled = styled.header`
  position: relative;
  width: 100%;
  margin: 3rem auto;

  .logo-wrapper {
    width: 100%;
    max-width: 40rem;
    margin: 0 auto;
    padding: 0 5rem 5rem;

    @media (min-width: 1025px) {
      width: calc(40%);
      padding: 0 12rem 0 4rem;
    }

    @media (min-width: 1200px) {
      padding: 0 8rem 0 8rem;
    }
  }

  .links-wrapper {
    align-self: flex-start;
    width: 100%;

    @media (min-width: 1025px) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      width: calc(60%);
    }

    &__link {
      width: 100%;
      text-align: center;

      @media (min-width: 768px) {
      }

      @media (min-width: 1025px) {
        width: calc(50% - 2rem);
        margin: 0 1rem;
        text-align: left;
      }

      &--one {
        margin-bottom: 5rem;

        @media (min-width: 1025px) {
          margin-bottom: 0;
          text-align: right;
        }
      }
    }
  }

  .background {
    position: absolute;
    top: -25rem;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: left bottom;
    transform: rotate(-30deg);
    background-color: #e5f3f4;
    z-index: -1;

    @media (min-width: 768px) {
      top: -20rem;
      left: 0;
    }

    @media (min-width: 1025px) {
      top: -3rem;
      left: 0;
    }
  }
`

const Header = ({ siteTitle, location }) => {
  const data = useStaticQuery(getData)
  const logoFluid =
    data.wordpressAcfOptions.options.swb_theme_main_logo.localFile
      .childImageSharp.fluid
  const logoAlt = data.wordpressAcfOptions.options.swb_theme_main_logo.alt_text
  return (
    <HeaderStyled>
      <WrapperDiv>
        <div className="logo-wrapper">
          {location === "/" ||
          location === "/privacy-policy" ||
          location === "/disclaimer" ? (
            <Link to="/">
              <Logo fluid={logoFluid} alt={logoAlt} />
            </Link>
          ) : (
            <a target="_blank" rel="noopener noreferrer" href="/">
              <Logo fluid={logoFluid} alt={logoAlt} />
            </a>
          )}
        </div>
        <div className="links-wrapper">
          <div className="links-wrapper__link links-wrapper__link--one">
            {location === "/dynamics365-finance-operations" ||
            location === "/dynamics365-finance-operations/" ? (
              <AnchorOne
                target="_blank"
                rel="noopener noreferrer"
                url="https://solutions.solvercanada.com/#mailchimpFormSection"
              >
                Request Demo
              </AnchorOne>
            ) : location === "/" ||
              location === "/budgeting-forecasting" ||
              location === "/budgeting-forecasting/" ||
              location === "/dynamics365-business-central" ||
              location === "/dynamics365-business-central/" ? (
              <ScrollButtonOne
                onClick={() => scrollTo(`#mailchimpFormSection`)}
              >
                Request Demo
              </ScrollButtonOne>
            ) : (
              <LinkOne url="/#mailchimpFormSection">Request Demo</LinkOne>
            )}
          </div>
          <div className="links-wrapper__link links-wrapper__link--two">
            <AnchorOneText url="tel:+1-403-387-8000">
              Questions? 403-387-8000
            </AnchorOneText>
          </div>
        </div>
      </WrapperDiv>
      <div className="background" />
    </HeaderStyled>
  )
}

export default Header
