const fontSizer = (
  minFont = 1.8,
  maxFont = 2.2,
  minScreen = 76.8,
  maxScreen = 110,
  mobileFont = 1.8
) => {
  return `
  font-size: calc(${mobileFont} * 1rem);

  @media (min-width: calc(${minScreen} * 10px)) {
    font-size: calc(
      (${minFont} * 1rem) + (${maxFont} - ${minFont}) *
        (((100vw - (${minScreen} * 1rem))) / (${maxScreen} - ${minScreen}))
    );
  }

  @media (min-width: calc(${maxScreen} * 10px)) {
    font-size: calc(${maxFont} * 1rem);
  }
  `
}

const paraBase = `
  color: #505b61;
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
`

export const bodyCopy = `
  ${paraBase};
  ${fontSizer && fontSizer(1.6, 1.8, 76.8, 150, 1.6)};
  line-height: 1.56;
`

export const bodyCopyOne = `
  ${paraBase};
  ${fontSizer && fontSizer(1.6, 1.8, 76.8, 150, 1.6)};
  line-height: 1.56;
`

export const bodyCopyTwo = `
${paraBase};
  ${fontSizer && fontSizer(1.6, 2, 76.8, 150, 1.8)};
  line-height: 1.5;
`

export const bodyCopyThree = `
  ${paraBase};
  ${fontSizer && fontSizer(1.6, 1.7, 76.8, 150, 1.6)};
  line-height: 1.5;
`

export const bodyCopyFour = `
  ${paraBase};
  ${fontSizer && fontSizer(1.4, 1.5, 76.8, 150, 1.6)};
  line-height: 1.5;
`
