import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import {
  colors,
  bodyCopyThree,
  bodyCopyFour,
  headlineTwo,
  standardWrapper,
} from "../../Utilities"

const getData = graphql`
  query footerOptions {
    footData: wordpressAcfOptions {
      options {
        swbtheme_footer_content
        swbtheme_switchback_credit
        swbtheme_global_address_title
        swbtheme_canada_address_title
        swbtheme_footer_solver_logo {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        swbtheme_site_can_phone
        swbtheme_site_glob_phone
        swbtheme_site_can_email
        swbtheme_site_glob_email
        swbtheme_site_can_address
        swbtheme_site_glob_address
        swbtheme_site_main_url
      }
    }
  }
`

const StyledFooter = styled.footer`
  overflow: hidden;

  .footerTop {
    background-color: ${colors.colorSecondary};

    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      max-width: 100rem;
      margin: 0 auto;
      padding: 2rem;

      @media (min-width: 768px) {
        flex-wrap: nowrap;
      }

      p,
      a {
        ${headlineTwo};
        margin: 0;
        color: ${colors.white};

        @media (max-width: 768px) {
          font-size: 1.8rem;
        }
      }

      &--btn {
        width: calc(100% - 2rem);

        @media (min-width: 768px) {
          width: calc(50% - 2rem);
        }
      }

      &--btn:first-of-type {
        text-align: center;

        @media (min-width: 768px) {
          position: relative;
          margin-right: 2rem;
          text-align: right;
        }

        @media (min-width: 768px) {
          &::after {
            position: absolute;
            top: 50%;
            right: -2.5rem;
            width: 1rem;
            height: 1rem;
            transform: translate(0, -50%);
            background: ${colors.white};
            border-radius: 50%;
            overflow: hidden;
            content: "";
          }
        }
      }

      &--btn:last-of-type {
        text-align: center;

        @media (min-width: 768px) {
          margin-left: 2rem;
          text-align: left;
        }
      }
    }
  }

  .footerContent {
    width: 100%;

    p {
      ${bodyCopyThree};
      color: ${colors.white};
    }
  }

  .footerContact {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;
    padding: 2rem;

    &__container {
      width: 100%;
      margin: 2rem auto;
      text-align: center;

      @media (min-width: 768px) {
        width: calc(33.33% - 4rem);
        margin: 0 2rem;
        text-align: left;
      }

      @media (min-width: 1025px) {
        width: calc(37.5% - 4rem);
        margin: 0 2rem;
      }

      h3,
      p,
      a {
        ${bodyCopyFour};
        margin: 0;
        margin-bottom: 0.5rem;
        color: ${colors.white};
      }

      a:hover {
        color: ${colors.colorSecondary};
      }

      h3 {
        font-weight: bold;
      }
    }

    &__container:first-of-type {
      width: 100%;
      max-width: 20rem;
      margin: arem auto;

      @media (min-width: 768px) {
        width: calc(33.33%);
        max-width: 100%;
      }

      @media (min-width: 1025px) {
        width: calc(25% - 4rem);
        margin: 0 2rem;
        padding: 1rem;
      }
    }
  }

  .footerBottom {
    position: relative;
    padding: 3rem 0;
    background-color: ${colors.colorPrimary};
    overflow: hidden;

    &__wrapper {
      ${standardWrapper};
      max-width: 85rem !important;
    }
  }

  .footerCopy {
    margin-top: 2rem;

    p,
    a {
      ${bodyCopyFour};
      margin: 0;
      margin-bottom: 0.5rem;
      color: ${colors.white};
      text-align: center;
    }
  }
`

const Footer = () => {
  const {
    footData: { options },
  } = useStaticQuery(getData)
  const swbCred = options.swbtheme_switchback_credit === "yes" ? true : false
  const mainSiteUrl = options.swbtheme_site_main_url
  const footerContent = options.swbtheme_footer_content
  const logoFluid =
    options.swbtheme_footer_solver_logo.localFile.childImageSharp.fluid
  const logoAlt = options.swbtheme_footer_solver_logo.alt_text
  const globTitle = options.swbtheme_global_address_title
  const globAddress = options.swbtheme_site_glob_address
  const globEmail = options.swbtheme_site_glob_email
  const globPhone = options.swbtheme_site_glob_phone
  const canaTitle = options.swbtheme_canada_address_title
  const canaAddress = options.swbtheme_site_can_address
  const canaEmail = options.swbtheme_site_can_email
  const canadaPhone = options.swbtheme_site_can_phone

  const friendlyUrl = mainSiteUrl.split("/")[mainSiteUrl.split("/").length - 1]

  return (
    <StyledFooter>
      <div className="footerTop">
        <div className="footerTop__wrapper">
          <div className="footerTop__wrapper--btn">
            <p>
              Call us: <a href={`+1${canadaPhone}`}>{canadaPhone}</a>
            </p>
          </div>
          <div className="footerTop__wrapper--btn">
            <a target="_blank" rel="noopener noreferrer" href={mainSiteUrl}>
              {friendlyUrl}
            </a>
          </div>
        </div>
      </div>
      <div className="footerBottom">
        <div className="footerBottom__wrapper">
          <div
            className="footerContent"
            dangerouslySetInnerHTML={{ __html: footerContent }}
          />

          <div className="footerContact">
            <div className="footerContact__container">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.solverglobal.com"
              >
                <Img fluid={logoFluid} alt={logoAlt} />
              </a>
            </div>
            <div className="footerContact__container">
              <h3>{globTitle}</h3>
              <div dangerouslySetInnerHTML={{ __html: globAddress }} />
              <div>
                <p>
                  Email: <a href={`mailto:${globEmail}`}>{globEmail}</a>
                </p>
                <p>
                  Telephone: <a href={`tel:+1${globPhone}`}>{globPhone}</a>
                </p>
              </div>
            </div>
            <div className="footerContact__container">
              <h3>{canaTitle}</h3>
              <div dangerouslySetInnerHTML={{ __html: canaAddress }} />
              <div>
                <p>
                  Email: <a href={`mailto:${canaEmail}`}>{canaEmail}</a>
                </p>
                <p>
                  Telephone: <a href={`tel:+1${canadaPhone}`}>{canadaPhone}</a>
                </p>
              </div>
            </div>
          </div>
          <div className="footerCopy">
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://solutions.solvercanada.com/privacy-policy"
              >
                Privacy Policy
              </a>
              <span> | </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://solutions.solvercanada.com/disclaimer"
              >
                Disclaimer
              </a>
            </p>
            <p>
              Copyright © {new Date().getFullYear()} Solver Canada.{" "}
              {swbCred && (
                <span>
                  Made in Airdrie. Designed and developed by{" "}
                  <a href="https://switchbackcreative.ca">
                    Switchback Creative Inc
                  </a>
                  . Built with ♡ and{" "}
                  <a href="https://www.gatsbyjs.org">Gatsby</a>.
                </span>
              )}{" "}
            </p>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
